import {
    Overlay,
    Container,
    Title,
    Button,
    Text,
    Flex,
    rem,
    Paper,
    Highlight,
    TextInput,
    Modal,
    Center
} from '@mantine/core';
import Autoplay from 'embla-carousel-autoplay';

import { Carousel, Embla } from '@mantine/carousel';
import heroImg1 from './images/bla.webp';
import { useMediaQuery } from '@mantine/hooks';

import heroImg2 from './images/img_1.png';
import heroImg2_small from './images/drone_small.png';
import heroImg3 from './images/ambulance.jpg';
import heroImg4 from './images/cruise-ship.jpg';
import heroImg5 from './images/manufacturing.jpg';
import heroImg5_small from './images/manufacturing_small.png';

import heroImg6 from './images/remote-access.jpg';
import heroImg6_small from './images/remote-access_small.png';

import classes from './HeroContentLeft.module.css';
import FloatingLabelClasses from './FloatingLabelInput.module.css';
import {useDisclosure} from "@mantine/hooks";
import {JoinWaitlist} from "./JoinWaitlist";
import {HeaderMenu} from "./HeaderMenu";
import {useEffect, useRef, useState} from "react";
import {
    IconArrowBearRight, IconArrowBigRight, IconArrowLeft, IconArrowLoopRight, IconArrowMoveRight,
    IconArrowRight, IconArrowRightCircle,
    IconArrowRightTail, IconCircleArrowLeft, IconCircleArrowRight,
    IconGauge,
    IconPlayerTrackNext
} from "@tabler/icons-react";
import {TrackedHashLink as HashLink} from "./TrackedHashLink";



let inFocus = true;

export function HeroContentLeft() {
    const [embla, setEmbla] = useState(null);
    const [opened, { open, close }] = useDisclosure(false);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const autoplay = useRef(Autoplay({ delay: 30000, stopOnInteraction: true }));

    const data = [
        {
            "mainText": <div> Secure & ultra-reliable connectivity anywhere </div>,
            "subText": "HyperPath combines Link Bonding, P2P Communication, and Zero Trust networking to enable reliable, low latency and secure communication to your most dynamic assets like BVLOS drones, autonomous vehicles, and mobile robots",
            "image": heroImg1,
            "opacity": 0,
            "textContainerOpacity": 0
        },

        // Secure and reliable connectivity for your dynamic assets
        // Multi connectivity to make it reliable, p2p to make it secure and minimum latency
        // HyperPath combines link bonding and P2P communication to enable reliable, low latency and secure communication to your most dynamic assets like BVLOS drones, autonomous vehicles, and mobile robots.

        //Connect your devices reliably & securely, anywhere
        //Effortless unbreakable and secure connectivity anywhere
        //serverless p2p VPN
        //The Simplest solution for secure and reliable connectivity anywhere
        // OR Easiest way to deploy secure and reliable connectivity anywhere
        // OR Seamless reliable connectivity in the most challenging environments
        // OR Seamless and highly-optimized link-bonding
        // OR deploy highly-optimized link-bonding seamlessly
        // OR Solution for reliability using multi-connectivity
        // OR Create an ultra-reliable and low-latency software-defined network
        // OR The Ultra-reliable software-defined network
        //Hyperpath lets you connect your devices in a ZeroTrust P2P virtual network with minimum latency, and lets them use
        // multiple network links simultaneously to increase capacity and enhance reliability.


        // HyperPath is a software solution combining link aggregation and P2P connectivity to let your mobile devices and unmanned vehicles communicate quickly, reliably and securely over unstable wireless links. Zero-touch deployment, Zero-trust security and no cloud infrastructure needed
        // HyperPath lets you connect your devices reliably, seamlessly and securely anywhere using an innovative combination of highly optimized link bonding and minimum latency Peer-to-Peer overlay network

        {
            "mainText": <div>Ultra-reliable connectivity for tele-operation</div>,
            "subText": "Combine multiple cellular operators as well as satellite for ultra-reliable connectivity during tele-operation of unmanned vehicles.",
            "image": isMobile ? heroImg2_small : heroImg2,
            "opacity": isMobile ? 0.3 : 0.5,
            "textContainerOpacity": isMobile ? 0.8 : 0
        },
        {
            "mainText": <div>Safety-critical communication</div>,
            "subText": "HyperPath protects your connection through sophisticated adaptive redundancy to guarantee uninterrupted connectivity",
            "image": heroImg3,
            "opacity": 0.3,
            "textContainerOpacity": 0.8
        },
        {
            "mainText": <div>High-performance on-board Internet </div>,
            "subText": "HyperPath intelligently combines the capacity of multiple cellular and satellite connections to provide high performance connectivity for any passenger vehicles  while minimizing cost.",
            "image": heroImg4,
            "opacity": 0.3,
            "textContainerOpacity": 0.8
        },
        {
            "mainText": <div>Cost-effective wireless  connectivity for  manufacturing </div>,
            "subText": "HyperPath enables combining multiple orthogonal WiFi networks simultaneously in factories to provide performance equivalent to private 5G at a fraction of the price.",
            "image": isMobile ? heroImg5_small : heroImg5,
            "opacity": 0.3,
            "textContainerOpacity": 0.8
        },
        {
            "mainText": <div>Secure remote access to shared resources </div>,
            "subText": "HyperPath connects your team's devices and resources easily and securely, anywhere they are.",
            "image": isMobile ? heroImg6_small : heroImg6,
            "opacity": 0.3,
            "textContainerOpacity": 0.8
        }
    ]

    useEffect(() => {
        const resetAutoplayTimer = () =>{
            autoplay.current.reset();
        };
        if (embla) {
            embla.on('select', resetAutoplayTimer);
        };
        return () => {if (embla) embla.off('select', resetAutoplayTimer); }

    }, [embla]);
    const slide =  (mainText, subText, image, opacity, textContainerOpacity) => {
        return (
            <Carousel.Slide >
                <div className={classes.hero}  style={{ backgroundImage : `url(${image})` }}>

                    <div className="h-full">
                        <Overlay
                            gradient="linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, .65) 40%)"
                            opacity={opacity}
                            zIndex={0}
                        />
                            <Container className={classes.container} size="xl">

                                <div className="p-5" style={{"backgroundColor": `rgba(18,29,47,${textContainerOpacity})` }}>

                                    <Container size="sm" p={0}>
                                    <Title className={classes.title}> {mainText} </Title>
                                    </Container>
                                        <Text className={classes.description} size="xl" mt="xl">

                                            {subText}

                                        </Text>
                                </div>

                                <Flex gap={isMobile ? 20 : 20} align=""  className="mt-5 " >
                                    {/*<TextInput*/}
                                    {/*    classNames={FloatingLabelClasses}*/}
                                    {/*    placeholder="Enter your email"*/}
                                    {/*    size="xl"*/}
                                    {/*    data-floating={true}*/}
                                    {/*    labelProps={{ 'data-floating': true }}*/}
                                    {/*    inputStyle={{backgroundColor: 'var(--mantine-color-dark-blue-10)'}}*/}
                                    {/*    labelStyle={{color: 'var(--mantine-color-white)'}}></TextInput>*/}

                                    <Button
                                        size= {isMobile ? "md" : "xl"}
                                        radius="xl"
                                        className={classes.control}
                                        onClick={open}
                                    >
                                        Join the waitlist
                                    </Button>
                                    <Modal
                                        padding={0}
                                        opened={opened}
                                        onClose={close}
                                        withCloseButton={false}
                                        title=""
                                        size="lg"
                                        overlayProps={{
                                            backgroundOpacity: 0.1,
                                        }}
                                        style={{ backgroundColor: 'lightblue' }}
                                    >
                                        <JoinWaitlist/>
                                    </Modal>
                                    <HashLink smooth to="/#benefits">
                                        <Button variant="default" radius="xl" size= {isMobile ? "md" : "xl"} className={classes.control}>
                                            Learn more ...
                                        </Button>
                                    </HashLink>
                                </Flex>
                            </Container>
                    </div>
                </div>
            </Carousel.Slide>
        );
    }

    const slides = data.map((item) => {
        return slide(item.mainText, item.subText, item.image, item.opacity, item.textContainerOpacity);
    });

    return (
        <div>
            <Carousel
                getEmblaApi={setEmbla}
                align="start"
                slidesToScroll={1}
                controlSize={ 50}
                withIndicators
                withControls={!isMobile}
                loop
                nextControlIcon={<IconArrowRight/>}
                previousControlIcon={<IconArrowLeft/>}
                plugins={[autoplay.current]}
            >
                {slides}
            </Carousel>
        </div>
    );
}
