
import ConicalLogo from "./images/Conical logo.svg";
import GuardLogo from "./images/DTIF-guard logo.png";
import ATechSynDrone from "./images/mistral_image_2.jpeg";
import MistralDiagram from "./images/mistral_diagram.png";
import ATechSYNLogo from "./images/ATechSYN logo.png";
export default function MistralCaseStudy()
{

    const body = [
        {
            "Project Overview" :
                <div>
                    The MISTRAL project is a collaboration between the Irish Defence Forces,  A-techSYN, and Tyndall National Institute to enhance communication capabilities through innovative drone technology. The project focuses on using AI-driven drones to serve as radio signal relays, significantly improving the range and reliability of VHF radio communication. These drones automatically optimize their positions to maximize radio coverage for ground-based users, amplifying the VHF radio coverage area by up to 625 times. This technology is especially vital for military operations in remote areas and disaster relief efforts, where rapid and far-reaching communication is crucial.
                </div>
        },
        {
            "HyperPath extends the range of VHF radio communication globally" :
                <div>
                    In addition to extending the radio coverage for soldiers, MISTRAL deploys HyperPath on its drones to extend the range of VHF radio communication globally by tunneling VHF radio packets over the internet via the HyperPath tunnel. This enables bi-directional VHF communication between soldiers and their command centers, even when they are located on the opposite sides of the globe.
                </div>
        },
        {
            "Using multi-connectivity to enhance reliability and reduce cost" :
                <div>
                    To allow HyperPath to transmit VHF data over the internet, the MISTRAL drones can be equipped with both a satellite link and multiple cellular modems. HyperPath makes use of all links in order to increase reliability of transmitting the VHF data as well as lower the cost by opportunistically transmitting data over cellular links whenever it is available in order to minimize the usage of the more expensive satellite link.
                </div>
        }

    ];

    const name = "mistral";
    const companyName = "ATechSYN";
    const logo = ATechSYNLogo;  // Assuming there's a GUARD logo
    const location = "Ireland";
    const industry = "Drone-assisted Communication";
    const title = "Enabling drone-assisted VHF communication over the internet for MISTRAL missions";
    const website = "https://a-techsyn.com";
    const logoWidth = 150;
    const diagram = MistralDiagram;

    const img = ATechSynDrone;
    return {
        body,
        name,
        logo,
        location,
        industry,
        title,
        website,
        logoWidth,
        img,
        companyName,
        diagram

    };

}