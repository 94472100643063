import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme, Button, Flex,
} from '@mantine/core';
import {
    IconGauge,
    IconUser,
    IconCookie,
    IconArrowsDiagonal,
    IconArrowsCross,
    IconArrowsJoin, IconUsersGroup, IconLock, IconClick, IconDrone, IconBuildingFactory, IconBuildingFactory2
} from '@tabler/icons-react';

import classes from './FeaturesCards.module.css';
import {FaBeer} from "react-icons/fa";
import {IconContext} from "react-icons";
import {GiDeliveryDrone} from "react-icons/gi";
import {PiFactoryBold} from "react-icons/pi";
import YoutubeVideo from "./YoutubeVideo";



export function HyperpathDemo() {
    const theme = useMantineTheme();



    return (
        <Container size="2xl" py="xl">
            <Group justify="center">

            </Group>

            <Title order={1} className={classes.title} ta="center" mt="sm" fw={900} c="white">
                <p className="text-white">HyperPath in action</p>
            </Title>

            <Text c="white" className={classes.description} ta="center" mt="md" mb={50}>
            </Text>

            <YoutubeVideo  ></YoutubeVideo>
        </Container>
    );
}