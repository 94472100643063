import {
    Image,
    Container,
    Title,
    Button,
    Group,
    Text,
    List,
    ThemeIcon,
    rem,
    SimpleGrid,
    Grid,
    Center, Flex, Anchor
} from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';
// import image from './image.svg';
import classes from './FeatureText.module.css';
import {TrackedHashLink as HashLink} from "./TrackedHashLink.jsx";
import {useMediaQuery} from "@mantine/hooks";
export function FeatureText({title, text, image, reversed, imgSize=290, learnMore=false}) {
    const isMobile = useMediaQuery('(max-width: 768px)');
    return (
        <Container size="xl" pb={{base: 45, md:30}} pt={{base: 45, md:30}}>

            <Grid  >
                <Grid.Col span={{md: 6, base: 12}} order={{md: reversed ? 2 : 1, base: 2}}>
                    <div className={classes.content} >
                        <Title order={2} className={classes.title} mt={{ base:"lg", md:0}} >
                            {title}
                        </Title>
                        <Text c="" mt="md">
                            {text}
                        </Text>
                        {learnMore &&

                            <Flex justify = "" className="mt-4 w-full">
                                <HashLink to={"/howItWorks#" + learnMore}>
                                    <Anchor c="deep-blue.2">
                                        Read more
                                    </Anchor>
                                </HashLink>
                            </Flex>
                        }

                    </div>
                </Grid.Col>
                <Grid.Col  span={{md: 6, base: 12}} order={{md: reversed ? 1 : 2, base: 1}}>
                    <div className="w-full flex justify-center items-center">
                        <img src={image} style={{"height": isMobile ? "auto" : imgSize }}  />
                    </div>
                </Grid.Col>
            </Grid>
        </Container>
    );
}