import {useEffect, useLayoutEffect} from "react";

export default function useScrollToHash() {
    useLayoutEffect(() => {
        //TODO: setTimeout with 0 made it work in Safari - i dont know why
        setTimeout(() => {
            const { hash } = window.location
            if (hash) {
                const id = hash.replace('#', '')
                const element = document.getElementById(id)
                if (element) {
                    element.scrollIntoView({ block: 'start', behavior: 'instant' })
                    // window.scrollBy(0, -55)
                }
            }
        }, 100)
    }, []);
}