import {HeaderMenu} from "./HeaderMenu";
import {Footer} from "./Footer";
import OurTeam from "./OurTeam";

export default function OurTeamPage()
{
    return (
        <div>
            <HeaderMenu/>
            <OurTeam/>
            <Footer/>
        </div>
    )
}