import {Container, rem, Title, Text, Center, Flex} from "@mantine/core";
import P2PMultiConnectivity from "./images/p2p-multi-connectivity.png";
import NatTraversal from "./images/nat-traversal.png";
import Hypernet from "./images/HyperNet.png";
import Replication from "./images/replication_diagram.png";
import Aggregation from "./images/aggregation_diagram.png";
import AuthSeq from "./images/authentication_seq.png"
import DiagramWithCaption from "./DiagramWithCaption";
import useScrollToHash from "./ScrollToHash";
export default function HowItWorks()
{
    const scrollToHash = useScrollToHash();

    return (

        <div >
            <Container size="xl">
                <Title c="white" fz={rem(50)} mt={50} mb={50}>
                    How It Works
                </Title>


                <Container ml={0} mr={0} pl={0} pr={0} size="lg">
                    <Title c="white" order={1}  mb={15}>
                        Overview
                    </Title>

                    <Text c="white" fz="lg">
                        HyperPath is a software solution that creates a Multi-connectivity P2P (Peer-To-Peer) Mesh VPN (Virtual Private Network) between your devices called a HyperNet.
                        <br></br>
                        <br></br>
                        Nodes (i.e. devices) in a HyperNet communicate over multiple P2P (Peer-To-Peer) connections simultaneously where each connection utilizes a different pair of network interfaces to transmit and receive data between the two nodes. This allows nodes to communicate with minimum latency as well as leverage all of their network interfaces to increase capacity and enhance reliability.
                        <br></br>
                        <br></br>
                        The HyperNet is managed by a central entity called a Controller, which oversees configuration and authentication to the network. The Controller also exposes a web dashboard for monitoring the performance of the HyperNet in real-time.
                        <br></br>
                        <br></br>
                        Below is an diagram of a HyperNet with 3 nodes: a drone, a cloud VM, and a laptop. The drone has 3 network interfaces, while the laptop and the cloud VM have one. As such, the drone will establish 3 P2P connections with the laptop and 3 P2P connections with the cloud VM, while the laptop and the cloud VM will establish 1 P2P connection between each other.

                        <DiagramWithCaption image={Hypernet} caption="Example HyperNet with 3 nodes" />

                    </Text>


                    <Title c="white" order={1} mb={15} mt={25}>
                        How to create a HyperNet
                    </Title>

                    <Text c="white" fz="lg" mt={10}>
                        In order to create a HyperNet, the user must create an account on the HyperPath admin console and create a HyperNet. Once done, the user needs to create a token for each node that will be added to the HyperNet.

                        <br></br>
                        <br></br>
                        The token is a secret key that is used by the node to authenticate to the HyperNet. Furthermore, all configuration parameters for the HyperNet are linked to the token, such as the IP of the device/node in the HyperNet, the list of network interfaces it should use, the multi-path scheduler etc..
                        <br></br>
                        <br></br>
                        Once a token is created, the user will need to install the HyperPath client on the device/node and provide the token to the client. The client will then use the token to authenticate to the HyperNet.
                        <br></br>
                        <br></br>
                        The figure below shows the steps required to create and add a node to a HyperNet.
                    </Text>
                    <DiagramWithCaption image={AuthSeq} caption="Sequence diagram for creating and adding a device to a HyperNet" imgSize="w-8/12" />

                    <Title c="white" order={1} mb={15} mt={25} id="p2p">
                        P2P Multi-connectivity in a HyperNet
                    </Title>

                    <Text c="white" fz="lg" mt={10}>
                        Once a node authenticates to a HyperNet, it will automatically retrieve a list of active nodes in the HyperNet from the HyperPath controller and attempt to connect to them by establishing one or more P2P connections, each utilizing a different pair of network interfaces between the two nodes.
                        <br></br>
                        <br></br>
                        P2P connectivity in a HyperNet is possible even when both devices are behind NAT (Network Address Translation) layers (i.e. no public IP), and without requiring port forwarding or any cloud infrastructure. This is possible HyperPath employs NAT traversal techniques to establish direct connectivity even in the presence of NAT.
                        <br></br>
                        <br></br>
                        By enabling P2P connectivity, HyperPath can significantly reduce latency between the two nodes, which is critical for applications such as tele-operation of unmanned vehicles. Furthermore, it eliminates the need for cloud infrastructure, which can significantly reduce cost and complexity, and increase scalability.
                        <br></br>
                        <br></br>
                        However, in cases where direct connectivity is not possible due to restrictive NATs or firewall rules, HyperPath will automatically establish a relay connection through a HyperPath server that is located optimally to minimize the latency between both devices.
                        <br></br>
                        <br></br>
                        The figure below shows an example of connectivity between two nodes that are both behind NAT. As shown, the drone has two cellular interfaces connected to different cellular operators (CellularA and CellularB) and the laptop only has a WiFi interface connected to a router. The two nodes establish two P2P connections between each other, one over each pair of interfaces: CellularA-WiFi, CellularB-WiFi.
                    </Text>


                    <DiagramWithCaption image={NatTraversal} caption="Diagram showing P2P connectivity when both nodes in a HyperNet are behind NAT" />

                    <Text c="white" fz="lg" mt={10}>
                    </Text>


                    <Title c="white" order={1}  mb={15} mt={45} id="scheduling">
                        Multi-path Scheduling
                    </Title>
                    <Text c="white" fz="lg" mt={10}>
                        Once two nodes are connected, the multi-path scheduler module will perform per-packet scheduling of the traffic across all the P2P connections between them. The specific scheduler used for a connection is configurable from the admin console. At the current time there are two schedulers: replication and aggregation.

                    </Text>




                    <Title c="white" order={3}  mb={15} mt={25} id="replication">
                        Replication scheduling
                    </Title>
                    <Text c="white" fz="lg" mt={10}>
                        The replication scheduler will transmit copies of the same packet across all P2P connections between the two nodes. On the receiver side, the HyperPath client will forward the first copy of a packet that arrives, and will make sure to discard any subsequent copies of the same packet. This scheduler is ideal for real-time applications as it greatly mitigates the effect of packet losses and latency fluctuations.
                        <br></br>
                        <br></br>
                        Below is an example of how the replication scheduler at work. The drone is transmitting 3 packets over 2 different connections. On connection 1, packets 2 and 3 were lost. On connection 2, only packet 1 was lost. The HyperPath client on the receiver will pick the correct packets from each connection and forward them in order, completely mitigating the effect of the losses on both connections.
                    </Text>


                    <DiagramWithCaption image={Replication} caption="Example of how replication scheduler enables complete data delivery despite losses on both connections" />


                    <Title c="white" order={3}  mb={15} mt={25} id="aggregation">
                        Aggregation scheduling
                    </Title>
                    <Text c="white" fz="lg" mt={10}>
                        The aggregation scheduler will schedule packets across the P2P connections according to their capacity. It estimates the capacity of the connections in real-time and will schedule packets in a way that will maximize the utilization of the connections while minimizing loss and latency. On the receiver side, the HyperPath client will re-order the packets before delivering them to the application even if they arrive out of order. This scheduler is ideal for non-real time data transfer applications as it can significantly reduce the time required to transfer large amounts of data.
                        <br></br>
                        <br></br>
                        Below is a diagram that shows how the aggregation scheduler works. The drone is transmitting 3 packets over 2 different connections. The HyperPath client estimated that connection 2 has double the capacity of connection 1, and thus transferred twice the amount of packets over it.
                    </Text>

                    <DiagramWithCaption image={Aggregation} caption="Example of how aggregation scheduler estimates and combines the capacity of two connections" />




                    <Title c="white" order={1}  mb={15} mt={25} id="deployment" >
                        The HyperPath client
                    </Title>
                    <Text c="white" fz="lg" mt={10}>
                        The HyperPath client is a software application that runs on each node in a HyperNet. It is installed as a VPN client on the node and as such requires no changes to the operating system, applications and no extra hardware.
                        <br></br>
                        <br></br>
                        The HyperPath client creates a virtual network interface on the node and traffic directed towards IP addresses of other nodes will be routed through it. The IP address of the virtual interface is configurable from the admin console.
                    </Text>
                </Container>
            </Container>


        </div>

    );
}