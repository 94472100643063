import logo from './logo.svg';
import './App.css';
import './tailwindcss.css';
import '@mantine/core/styles.css';
import '@mantine/ds/styles.css';
import '@mantine/carousel/styles.css';
import '@mantine/notifications/styles.css';
import { Notifications } from '@mantine/notifications';


import {Box, Button, Container, createTheme, MantineProvider, useMantineTheme,} from '@mantine/core';
import {HeaderMenu} from "./HeaderMenu";

import {Footer} from "./Footer";
import CaseStudyPage from "./CaseStudyPage";
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import MainPage from "./MainPage";
import OurTeam from "./OurTeam";
import OurTeamPage from "./OurTeamPage";
import HowItWorksPage from "./HowItWorksPage";
import ReactGA from "react-ga4";

import {useEffect} from "react";

function App() {


    const customTheme = createTheme({
        colors: {
            'dark-blue': [
                "rgba(6,13,24,0.63)",
                "#071425",
                "#071425",
                "#0F1D32",
                "#071425",
                "#071425",
                "#071425",
                "#071425",
                "#071425",
                "#071425",
                "#53EFE3"],
            'deep-blue': [
                '#4EC3B4',
                '#3CAEA3',
                '#53eee3',
                '#1B4847',
                '#0B2525',
                '#4EC3B4',
                '#3CAEA3',
                '#2C7B7A',
                '#1B4847',
                '#0B2525'],

            'dark':[
                '#c1c2c5',
                '#a6a7ab',
                '#909296',
                '#5c5f66',
                '#123360',
                '#2c2e33',
                '#0a1d34',
                '#071425',
                '#141517',
                '#101113',
            ],
        },
        primaryColor: 'deep-blue',
        primaryShade: 1,
        fontFamily: 'Inter,ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol,Noto Color Emoji'
    });
//#040d19;



    ReactGA.initialize("G-3DRM9FRPSZ");

    ReactGA.send({ hitType: "pageview", page: window.location.pathname , location: window.location.href , title: "Home Page"  });
    console.log("sending pageview",
        window.location.pathname,
        window.location.href,
        document.title);


    return (
        <BrowserRouter>
            <MantineProvider theme={customTheme} defaultColorScheme="dark">
                <Notifications/>
                <Routes>
                    <Route path="/team" element={<OurTeamPage/>} />
                    <Route path="/case-studies/:name" element={<CaseStudyPage/>} />
                    <Route path="/howItWorks" element={<HowItWorksPage/>} />
                    <Route path="*" element={<MainPage/>} />
                </Routes>
     {/*           <Box bg="dark-blue.0" >
                    <HeaderMenu/>
                    <OurTeam/>
                    <Footer/>
                </Box>*/}

                {/*<Box bg="dark-blue.0" >*/}
                {/*    <HeaderMenu/>*/}
                {/*    <CaseStudyPage/>*/}
                {/*    <Footer/>*/}
                {/*</Box>*/}

                {/*<MainPage/>*/}

            </MantineProvider>
        </BrowserRouter>
  );
}

export default App;
