import {TextInput, Textarea, SimpleGrid, Group, Title, Button, Card} from '@mantine/core';
import { useForm } from '@mantine/form';
import emailjs from 'emailjs-com';
import { notifications } from '@mantine/notifications';
import classes from './JoinWaitlist.module.css';
export function JoinWaitlist() {
    const form = useForm({
        initialValues: {
            name: '',
            email: '',
            message: '',
        },
        validate: {
            name: (value) => value.trim().length < 2,
            email: (value) => !/^\S+@\S+$/.test(value),
            message: (value) => value.trim().length === 0,

        },
    });


    const joinWaitlistSubmit = (e) =>
    {
        console.log("joining waitlist ", e);
        emailjs.sendForm('service_7mosfu7', 'template_apt80sk',e.target, '5Yn6yi4eAlbFmXZRH')
            .then((result) => {
                console.log('Email successfully sent!', result.text);
                notifications.show({
                    title: 'Success!',
                    message: 'Request sent successfully. We will get back to you soon!',
                })
            }, (error) => {
                console.log('Failed to send email.', error.text);
                notifications.show({
                    color: 'red.9',
                    title: 'Failure to send request',
                    message: error.text,
                    classNames: classes
                })
            });
    }

    return (
        <Card className="m-0 p-0">
            <form onSubmit={ e => form.onSubmit(() => joinWaitlistSubmit(e) )(e) }>
                <Title
                    order={2}
                    size="h1"
                    fw={900}
                    ta="center"
                    c="white"
                >
                    Join the waitlist!
                </Title>

                <SimpleGrid cols={{ base: 1, sm: 2 }} mt="xl">
                    <TextInput
                        label="Name"
                        c="white"
                        placeholder="Your name"
                        name="name"
                        variant="filled"
                        {...form.getInputProps('name')}
                        required
                    />
                    <TextInput
                        c="white"
                        label="Email"
                        placeholder="Your email"
                        name="email"
                        variant="filled"
                        {...form.getInputProps('email')}
                        required
                    />
                </SimpleGrid>

                <Textarea
                    c="white"
                    mt="md"
                    label="What will you use HyperPath for"
                    placeholder=""
                    maxRows={10}
                    minRows={5}
                    autosize
                    name="message"
                    variant="filled"
                    {...form.getInputProps('message')}
                    required
                />

                <Group justify="center" mt="xl">
                    <Button type="submit" size="md" >
                        Join the list
                    </Button>
                </Group>
            </form>
        </Card>
    );
}