import {IconBookmark, IconBrandLinkedin, IconHeart, IconMail, IconShare} from '@tabler/icons-react';
import {
    Card,
    Image,
    Text,
    ActionIcon,
    Badge,
    Group,
    Center,
    Avatar,
    useMantineTheme,
    rem, Spoiler,
} from '@mantine/core';


import classes from './BioCard.module.css';

export function BioCard({image, name, bio, email, linkedin}) {
    const linkProps = { href: 'https://mantine.dev', target: '_blank', rel: 'noopener noreferrer' };
    const theme = useMantineTheme();

    return (
        <div className="max-w-sm"  >
            <Card withBorder radius="md"  >
                <Card.Section>
                    <Image src={image} style={{ }} />
                </Card.Section>

                <Text className={classes.title} c="white" fw={700} mb={30}>
                    {name}
                </Text>

                <Spoiler maxHeight={110}
                         showLabel = {<Text fz="lg" c="deep-blue.2">Read more</Text>}
                         hideLabel = {<Text  fz="lg" c="deep-blue.2">Hide</Text>}
                         transitionDuration={0} >

                    <Text  fz="lg" >
                        {bio}
                    </Text>
                </Spoiler>

                <Group justify="space-between" className={classes.footer}>

                    <Group gap={8} mr={0}>
                        <ActionIcon
                            className={classes.action}
                            size={40}
                            component="a"
                            href={`mailto:${email}`}
                        >
                            <IconMail style={{ width: rem(35), height: rem(35) }} color={theme.colors["deep-blue"][0]} />
                        </ActionIcon>
                        <ActionIcon
                            component="a"
                            className={classes.action}
                            size={40}
                            href={linkedin}
                        >
                            <IconBrandLinkedin
                                color={theme.colors["deep-blue"][0]}
                                style={{ width: rem(35), height: rem(35) }}
                            />
                        </ActionIcon>

                    </Group>

                </Group>
            </Card>
        </div>
    );
}