import {Center, Container, Flex, Text} from "@mantine/core";
import {useMediaQuery} from "@mantine/hooks";

export default function DiagramWithCaption({image, caption, imgSize="w-10/12"})
{
    const isMobile = useMediaQuery('(max-width: 900px)');

    return (
        <Flex direction="column" align="center">
            <img src={image} className= { (isMobile ? "w-full" : imgSize) + " mb-5 mt-10"}/>

            <Container size="sm" mb={25}>
                <Center>
                    <Text align="center" c="gray.3" fz="lg">
                        {caption}
                    </Text>
                </Center>
            </Container>
        </Flex>
    );

}