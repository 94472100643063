import {Container, Group, Anchor, Flex, Button, Modal, Center} from '@mantine/core';
import  logo  from './images/hyperpath_logo.png';
import classes from './Footer.module.css';
import {TrackedHashLink as HashLink} from "./TrackedHashLink";
import {ContactUs} from "./ContactUs";
import {useDisclosure} from "@mantine/hooks";

const links = [
    { link: '/#Features', label: 'Features' },
    { link: '/#CaseStudies', label: 'Case Studies' },
    { link: '/HowItWorks#top', label: 'How it works' },
    { link: '/Team#top', label: 'Our Team' },
];

export function Footer() {
    const [opened, { open, close }] = useDisclosure(false);

    const items = links.map((link) => (
        <HashLink
            smooth
            to={link.link}
        >
            {link.label}
        </HashLink>
    ));

    return (
        <div className={classes.footer}>
            <Container className={classes.inner}>
                <HashLink to="/#top">
                    <Flex>
                            <img src={logo} className="h-9 w-8 mr-2 " />
                            <div className="flex items-center justify-center pr-2">
                                            <span className="h-fit text-2xl text-white font-bold ">
                                                HyperPath
                                            </span>
                            </div>
                    </Flex>
                </HashLink>

                    <Group className={classes.links} justify="center">
                            {items}
                            <Button  onClick={open}>Contact US</Button>
                        <Modal
                            padding={0}
                            opened={opened}
                            onClose={close}
                            withCloseButton={false}
                            title=""
                            size="lg"
                            overlayProps={{
                                backgroundOpacity: 0.1,
                            }}
                            style={{ backgroundColor: 'lightblue' }}
                        >
                            <ContactUs/>
                        </Modal>
                    </Group>

            </Container>
        </div>
    );
}