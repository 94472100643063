import {HashLink} from "react-router-hash-link";
import ReactGA from "react-ga4";

export function TrackedHashLink(props)
{
    const track = () =>
    {
        ReactGA.initialize("G-3DRM9FRPSZ");
        ReactGA.send({ hitType: "pageview", page: window.location.pathname , location: window.location.href , title: props.to });

        // ReactGA.event({
        //     category: "your category",
        //     action: "switched page",
        //     label: "your label", // optional
        //     value: 99, // optional, must be a number
        //     nonInteraction: true, // optional, true/false
        //     transport: "xhr", // optional, beacon/xhr/image
        // });
    }

    return <HashLink {...props}  onClick={track}/>

}