import {Anchor, Card, Center, Container, Flex, Highlight, rem, Text, Title, useMantineTheme, Image} from "@mantine/core";
import ConicalLogo from "./images/Conical logo.svg";
import { useMediaQuery } from '@mantine/hooks';

export default function CaseStudy({title, name, location, industry, logo, body, website, logoWidth, diagram}) {

    const theme = useMantineTheme();
    const isMobile = useMediaQuery('(max-width: 768px)');
    const shouldWrap = useMediaQuery('(max-width: 1200px)');
    console.log("name ", name);
    return (
      <Container size="xl"  >
          <Title
              // highlight={[name]}
              c="white"
              mb={50}
              fw={500}
              fz={{"base":rem(35),  "md": rem(50)}}
          >
                {title}
          </Title>

          <Flex wrap={shouldWrap ? "wrap" : ""} gap={shouldWrap ? 30 : 0}>


              <div>

                  <Center>
                      <img src={diagram}  className= { (isMobile ? "w-full " : " w-10/12 ") + " mb-10 "}/>
                  </Center>
                  {body.map((section, index) => {
                      const [title, content] = Object.entries(section)[0];
                      return (
                          <div key={index} >
                              <Title order={2} c="white" mb={15} mt={index !== 0 ? 50 : 0}>
                                  {title}
                              </Title>
                              <Text c="white">
                                  {content}
                              </Text>
                          </div>
                      );
                  })}


              </div>

              <Card className="h-fit" ml={shouldWrap ? 0 : 100 } size="xl" withBorder  radius="md" style={{width:rem(1100)}}>
                      <Card.Section mb={30} mt={10} >
                          <Center>
                            <img src={logo} style={{width:rem(logoWidth)}}/>
                          </Center>
                      </Card.Section>
                      <Card.Section mb={10} ml={5}>
                          <Flex direction="column" align="center">
                            <Title order={4}>Industry</Title>
                            <Text c="dimmed" mb={20}>{industry}</Text>
                            <Title order={4}>Location</Title>
                            <Text c="dimmed" mb={20}>{location}</Text>
                            <Title order={4}>Website</Title>
                            <Anchor href={website} c="var(--mantine-color-dark-blue-10)"> {website} </Anchor>
                          </Flex>

                      </Card.Section>

              </Card>
          </Flex>


      </Container>
    );
}