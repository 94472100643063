
import ConicalLogo from "./images/Conical logo.svg";
import ATechSYNLogo from "./images/ATechSYN logo.png";

import ATechSynDrone from "./images/Guard_photo.png";
import GuardDiagram from "./images/guard_diagram.png";

export default function GuardCaseStudy()
{

    const body = [
        {
            "Project Overview" :
                <div>
                    The GUARD Project brings together the Irish Navy and a consortium of Irish companies and academic institutes in order to create maritime drones and accompanying technology to assist in drug interdiction along the Irish coastline. The technical goal of the project is to allow the drones to operate autonomously, with minimal human intervention, in order to patrol the coastline and to provide real-time data to the Irish Navy in the form of video and sensor data that are then used to construct real-time Virtual Reality models of the coastline and nearby ships.
                </div>
        },
        {
            "Communication challenges" :
                <div>
                    The GUARD drones rely mainly on MM-Wave (Millimeter Wave) communication to transmit and receive data. While MM-Wave communication is ideal for high bandwidth, low latency communication, it is also highly unstable due to the high frequency and susceptible to interference from the environment. Since the drones are required to operate in a variety of weather conditions, including rain, fog, and snow, this makes relying on MM-Wave communication alone not feasible.
                </div>
        },
        {
            "Combining Cellular, Satellite and MM-Wave communication with HyperPath" :
                <div>
                    To overcome the reliability limitation with mm-wave links, the GUARD Project integrates HyperPath’s multi-connectivity capability in order to combine mm-wave links with cellular and satellite links. This allows the cellular and satellite links to seamlessly cover up any disruptions the mm-wave link, and to further augment its capacity through its aggregation capability.
                </div>
        }

    ];

    const name = "guard";
    const companyName = "DTIF GUARD";
    const logo = ATechSYNLogo;  // Assuming there's a GUARD logo
    const location = "Ireland";
    const industry = "Maritime drone surveillance";
    const title = "Bridging communication gaps for A-techSYN drones in GUARD’s maritime missions";
    const website = "https://a-techsyn.com";
    const logoWidth = 150;
    const diagram = GuardDiagram;

    const img = ATechSynDrone;
    return {
        body,
        name,
        logo,
        location,
        industry,
        title,
        website,
        logoWidth,
        img,
        companyName,
        diagram

    };

}