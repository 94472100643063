import {Box, Container, rem, Title} from "@mantine/core";
import {HeaderMenu} from "./HeaderMenu";
import {HeroContentLeft} from "./HeroContentLeft";
import {Footer} from "./Footer";
import HowItWorks from "./HowItWorks";
import useScrollToHash from "./ScrollToHash";

export default function HowItWorksPage()
{
    return (
        <div>
            <Box bg="dark-blue.0" pb={0}>
                <HeaderMenu/>
            </Box>


            <HowItWorks/>

            <Footer/>

        </div>
    );
}