import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme, Button, Flex, Center, Overlay,
} from '@mantine/core';

import classes from './FeaturesCards.module.css';

import {Carousel, Embla} from "@mantine/carousel";
import {TrackedHashLink as HashLink} from "./TrackedHashLink";
import conicalVehicle from "./images/conical_vehicle.png";
import conicalTeleoperate from "./images/conical_teleoperate.png";
import atechSynDrone from "./images/ATechSYN_drone.png";
import {useCallback, useEffect, useState} from "react";

import ConicalCaseStudy from "./ConicalCaseStudy";
import GuardCaseStudy from "./GuardCaseStudy";
import MistralCaseStudy from "./MistralCaseStudy";
import {useMediaQuery} from "@mantine/hooks";
export function CaseStudiesSection() {
    const theme = useMantineTheme();
    const isMobile = useMediaQuery('(max-width: 768px)');

    const [selected, setSelected] = useState(0);
    const [embla, setEmbla] = useState<Embla | null>(null);

    const handleScroll = useCallback(() => {
        if (!embla) return;
        setSelected(embla.selectedScrollSnap());
    }, [embla, setSelected]);

    console.log("selcted is ", selected);

    useEffect(() => {
        if (embla) {
            embla.on('scroll', handleScroll);
            handleScroll();
        }
    }, [embla]);


    const caseStudies = [ ConicalCaseStudy(), GuardCaseStudy(), MistralCaseStudy()];

    const data = caseStudies.map((caseStudy, index) => {
        return (
                <Carousel.Slide key={index}>
                    {selected !== index
                        ?
                        <Overlay color="#000" backgroundOpacity={0.35} blur={5} />
                        :
                        <div/>
                    }
                    <Flex gap={10} className="w-full">
                        <Card withBorder style={{width: isMobile ? "100%" : "28%" }}>
                            <Flex direction="column" justify="space-evenly"  className="h-full">
                                <Title order={isMobile ? 2 : 1} c="white" >
                                    {caseStudy.title}
                                </Title>
                                <Center>
                                    <HashLink to={"/case-studies/"+caseStudy.name+"#top"}>
                                        <Button variant="outline" size= {isMobile ? "md" : "lg"} component="a" mb={isMobile ? 40 : 20 } mt={isMobile ? 40 : 20}
                                                >
                                            Read the case study
                                        </Button>
                                    </HashLink>
                                </Center>
                            </Flex>
                            {/*<Title c="white" order={2}>*/}
                            {/*    {caseStudy.companyName}*/}
                            {/*</Title>*/}
                            <Center>
                                <img src={caseStudy.logo} className="w-2/3"/>
                            </Center>
                            {/*<Title order={6} c="dimmed">*/}
                            {/*    {caseStudy.industry}*/}
                            {/*</Title>*/}
                        </Card>
                        <Card p={0} m={0} style={{width:"70%"}} visibleFrom="sm">
                            <img src={caseStudy.img} className="h-full w-full"/>
                        </Card>
                    </Flex>
                </Carousel.Slide>
            );

    });
    return (
        <Container size="2xl" py="xl">
            <Group justify="center">

            </Group>

            <Title order={1} className={classes.title} ta="center" mt="sm" c="white"  fw={900} >
                Case Studies
            </Title>

            <Text c="dimmed" className={classes.description} ta="center" mt="sm" mb={50}>
            </Text>

            <Carousel
                // slideSize={{ base: '100%', sm: '50%' }}
                // slideGap={{ base: rem(2), sm: 'xl' }}
                align="center"
                slidesToScroll={1}
                withIndicators
                slideGap="sm"
                slideSize="80%"
                getEmblaApi={setEmbla}
                loop
            >
                {data}
            </Carousel>

        </Container>
    );
}