import {FeatureText} from "./FeatureText";
import heroImage from "./images/hyperpath_hero_image.png";
import replicationImg from "./images/replication.png";
import p2pVpnImg from "./images/p2p_vpn_2.png";
import deploymentImg from "./images/deployment_2.png";
import performanceImg from "./images/performance.png";
import {Text, Title} from "@mantine/core";
import classes from "./FeaturesCards.module.css";


export default function Features()
{
    return(
        <div>
            <Title order={1} className={classes.title} ta="center" mt={20} fw={900} >
                <p className="text-white">Features</p>
            </Title>

            <Text c="dimmed" className={classes.description} ta="center" mt="md" mb={30}>
            </Text>
            <FeatureText
                title={"Multi-path aggregation"}
                text={"HyperPath is able to dynamically discover and use all the available capacity of all your links even for a single traffic flow. It will split the application data across the links " +
                    "according to their capacity and ensure they are delivered in-order at the receiver. \n"}
                image={heroImage}
                imgSize={400}
                learnMore={"aggregation"}
            />

            <FeatureText
                title={"Multi-path replication"}
                text={"To ensure un-interrupted communication, HyperPath can send copies of application traffic across all links to mitigate even the most brief disruptions over any single links. On the receiver side, it will forward the first copy of a packet that arrives on any link greatly reducing losses and latency fluctuations\n"}
                image={replicationImg}
                imgSize={400}
                reversed={true}
                learnMore={"replication"}
            />

            <FeatureText
                title={"Zero-trust P2P Virtual Network"}
                text={"HyperPath creates a Zero-trust P2P virtual network on top of its multi-connectivity technology, enabling secure communication between your devices with minimum latency and with no need for cloud infrastructure, public addresses, or port forwarding \n"}
                image={p2pVpnImg}
                reversed={false}
                imgSize={450}
                learnMore={"p2p"}
            />

            <FeatureText
                title={"One-click install and deploy"}
                text={"HyperPath is deployed in user-space as a VPN client via a simple application install. It can be installed and deployed on most main-stream platforms with one command or click \n"}
                image={deploymentImg}
                reversed={true}
                imgSize={250}
                learnMore={"deployment"}
            />

            <FeatureText
                title={"Real-time performance monitoring"}
                text={"HyperPath provides a web dashboard which allows you to monitor your device's network performance in real-time with extremely high granularity\n"}
                image={performanceImg}
                reversed={false}
                imgSize={210}
            />




        </div>
    )
}