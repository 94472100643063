import React from 'react';

function YoutubeVideo() {
    return (
        <div className="flex w-full justify-center">
            <div className="w-11/12 aspect-video  max-w-screen-lg">

                <iframe
                    className="w-full h-full"
                    src="https://www.youtube.com/embed/OHC7dxDxZJw"
                    title="YouTube video player"
                    frameborder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; fullscreen"
                    >
                </iframe>
            </div>

        </div>
    );
}

export default YoutubeVideo;
