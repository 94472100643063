import {
    HoverCard,
    Group,
    Button,
    UnstyledButton,
    Text,
    SimpleGrid,
    ThemeIcon,
    Anchor,
    Divider,
    Center,
    Box,
    Burger,
    Drawer,
    Collapse,
    ScrollArea,
    rem,
    useMantineTheme, Flex, Modal,
} from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import {
    IconNotification,
    IconCode,
    IconBook,
    IconChartPie3,
    IconFingerprint,
    IconCoin,
    IconChevronDown,
} from '@tabler/icons-react';
import classes from './HeaderMenu.module.css';
import logo from "./images/hyperpath_logo.png"
import {TrackedHashLink as HashLink} from "./TrackedHashLink";
import {JoinWaitlist} from "./JoinWaitlist";
import {ContactUs} from "./ContactUs";

const mockdata = [
    {
        icon: IconCode,
        title: 'Open source',
        description: 'This Pokémon’s cry is very loud and distracting',
    },
    {
        icon: IconCoin,
        title: 'Free for everyone',
        description: 'The fluid of Smeargle’s tail secretions changes',
    },
    {
        icon: IconBook,
        title: 'Documentation',
        description: 'Yanma is capable of seeing 360 degrees without',
    },
    {
        icon: IconFingerprint,
        title: 'Security',
        description: 'The shell’s rounded shape and the grooves on its.',
    },
    {
        icon: IconChartPie3,
        title: 'Analytics',
        description: 'This Pokémon uses its flying ability to quickly chase',
    },
    {
        icon: IconNotification,
        title: 'Notifications',
        description: 'Combusken battles with the intensely hot flames it spews',
    },
];

export function HeaderMenu() {
    const [drawerOpened, { toggle: toggleDrawer, close: closeDrawer }] = useDisclosure(false);
    const [linksOpened, { toggle: toggleLinks }] = useDisclosure(false);
    const theme = useMantineTheme();
    const [opened, { open, close }] = useDisclosure(false);

    const links = mockdata.map((item) => (
        <UnstyledButton className={classes.subLink} key={item.title}>
            <Group wrap="nowrap" align="flex-start">
                <ThemeIcon size={34} variant="default" radius="md">
                    <item.icon style={{ width: rem(22), height: rem(22) }} color={theme.colors['deep-blue'][0]} />
                </ThemeIcon>
                <div>
                    <Text size="sm" fw={500}>
                        {item.title}
                    </Text>
                    <Text size="xs" c="dimmed">
                        {item.description}
                    </Text>
                </div>
            </Group>
        </UnstyledButton>
    ));

    return (

        <div className="flex items-center justify-center w-screen ">
            <Box pb={10} pt={10} className=" self-center w-full max-w-screen-xl" >
                <header className={classes.header}>
                    <Flex justify="space-between" h="100%" align="center">
                        <div className="flex items-center h-full">


                            <HashLink to="/#top">
                                <div className="flex justify-center mr-10">
                                    <img src={logo}  className="h-9 w-8 mr-1" />
                                    <div className="flex items-center justify-center pr-2">
                                        <span className="h-fit text-lg text-white font-bold ">
                                            HyperPath
                                        </span>
                                    </div>
                                </div>
                            </HashLink>
                            <Group h="100%" gap={0} visibleFrom="md">

                                <HashLink to="/#Features" className={classes.link}>
                                        Features
                                </HashLink>

                                <HashLink to="/#CaseStudies" className={classes.link}>
                                    Case studies
                                </HashLink>
                                <HashLink to="/howItWorks" className={classes.link}>
                                    How it Works
                                </HashLink>
                                <HashLink to="/team" className={classes.link}>
                                    Our team
                                </HashLink>
                            </Group>
                        </div>

                        <Flex gap={20}>
                            <Button  onClick={open}>Contact US</Button>
                            <Modal
                                padding={0}
                                opened={opened}
                                onClose={close}
                                withCloseButton={false}
                                title=""
                                size="lg"
                                overlayProps={{
                                    backgroundOpacity: 0.1,
                                }}
                                style={{ backgroundColor: 'lightblue' }}
                            >
                                <ContactUs/>
                            </Modal>
                            <Burger opened={drawerOpened} onClick={toggleDrawer} hiddenFrom="md" />
                        </Flex>
                    </Flex>
                </header>

                <Drawer
                    opened={drawerOpened}
                    onClose={closeDrawer}
                    size="100%"
                    padding="md"
                    title=""
                    hiddenFrom="md"
                    zIndex={1000000}
                >
                    <ScrollArea h={`calc(100vh - ${rem(80)})`} mx="-md">
                        <Divider my="sm" />

                        <HashLink to="/#Features" className={classes.link} onClick={toggleDrawer}>
                            Features
                        </HashLink>
                        <a href="/#CaseStudies" className={classes.link} onClick={toggleDrawer}>
                            Case Studies
                        </a>
                        <a href="/howItWorks" className={classes.link}>
                            How it Works
                        </a>
                        <a href="/team" className={classes.link}>
                            Our Team
                        </a>
                        <Divider my="sm" />

                    </ScrollArea>
                </Drawer>
            </Box>
        </div>
    );
}