
import CaseStudy from "./CaseStudy";
import ConicalCaseStudy from "./ConicalCaseStudy";
import GuardCaseStudy from "./GuardCaseStudy";
import {useParams} from "react-router-dom";
import {HeaderMenu} from "./HeaderMenu";
import {Footer} from "./Footer";
import MistralCaseStudy from "./MistralCaseStudy";
export default function CaseStudyPage() {
    const conicalData = ConicalCaseStudy();
    const guardData = GuardCaseStudy();

    const { name} = useParams();

    let curData = {};
    if (name === "conical") {
        curData = conicalData;
    }
    else if (name === "guard") {
        curData = guardData;
    }
    else if (name === "mistral")
    {
        curData = MistralCaseStudy();
    }


    return (
        <div>
            <HeaderMenu/>
            <CaseStudy
                    title={curData.title}
                    name={curData.name}
                    logo={curData.logo}
                    body={curData.body}
                    location={curData.location}
                    industry={curData.industry}
                    website={curData.website}
                    logoWidth={curData.logoWidth}
                    diagram = {curData.diagram}
                    />
            <Footer/>
        </div>





    )
}