import {Box, Container} from "@mantine/core";
import {HeaderMenu} from "./HeaderMenu";
import {HeroContentLeft} from "./HeroContentLeft";
import {Customers} from "./Customers";
import Features from "./Features";
import {CaseStudiesSection} from "./CaseStudiesSection";
import {HyperpathDemo} from "./HyperpathDemo";
import {Footer} from "./Footer";
import {WhyUsSection} from "./WhyUsSection";
import UseCases from "./UseCases";


export default function MainPage()
{
    return (
        <div className="flex-row">

            <Box bg="dark-blue.0" pb={0}>
                <HeaderMenu/>
                <HeroContentLeft/>

            </Box>
            <Box bg="dark-blue.0" pb={0} id="benefits">

                <WhyUsSection/>
            </Box>

            <Box bg="dark-blue.2">
                <HyperpathDemo/>
            </Box>

            <div id="CaseStudies">

                <Box bg="dark-blue.0">
                    <CaseStudiesSection/>

                </Box>
            </div>

            <div>
                {/*<UseCases/>*/}
            </div>

            <div id="Features">

                <Container bg="dark-blue.2" size="xxl" >
                    <Features></Features>
                </Container>
            </div>





            <Footer/>
        </div>
    );
}
