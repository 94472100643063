import {
    Badge,
    Group,
    Title,
    Text,
    Card,
    SimpleGrid,
    Container,
    rem,
    useMantineTheme, Flex, Button, Center, Anchor,
} from '@mantine/core';
import {
    IconGauge,
    IconUser,
    IconCookie,
    IconGraph,
    IconSignal4g,
    IconCellSignal5,
    IconWifi,
    IconPlus,
    IconSatellite,
    IconMoneybag,
    IconWorldDollar,
    IconCurrencyDollarAustralian,
    IconCoins,
    IconClockBolt,
    IconBolt,
    IconTopologyComplex,
    IconServer,
    IconCloudCancel,
    IconCloudOff,
    IconDownload,
    IconDeviceLaptop,
    IconArrowBarRight, IconArrowLeftRight, IconArrowsLeftRight
} from '@tabler/icons-react';
import classes from './WhyUsSection.module.css';
import {TrackedHashLink as HashLink} from "./TrackedHashLink";


export function WhyUsSection() {


    const combinedConnections = (props) => {
        return (
            <Flex>
                <IconWifi {...props} />
                <Title c="white">+</Title>
                <IconCellSignal5 {...props} />
                <Title c="white">+</Title>
                <IconSatellite {...props} />
            </Flex>
        );
    }

    const p2pBenefits = (props) => {
        return (
            <Flex>
                <IconDeviceLaptop {...props} />
                <IconArrowsLeftRight {...props} />
                <IconDeviceLaptop {...props} />
            </Flex>
        );
    }

    const deployment = (props) => {
        return (
            <Flex>
                <IconDownload {...props} />
            </Flex>
        );
    }
    const mockdata = [
        {
            title: 'Enhance reliability and increase capacity with link-bonding',
            description:
                'HyperPath enables your network assets to communicate using multiple different network link simultaenously, in order to combine  their available bandwidth or to enhance communication reliability.',
            icon: combinedConnections,
            link: "scheduling"
        },
        {
            title: 'Reduce cloud cost and minimize latency via P2P communication',
            description:
                'HyperPath creates a secure peer-to-peer virtual network between your devices, allowing them to communicate directly with minimum latency and without the need for cloud servers, public IPs or port forwarding.',
            icon: p2pBenefits,
            link: "p2p"
        },
        {
            title: 'Deploy seamlessly with a single app install',
            description:
                'HyperPath can be installed on all mainstream platforms with a single app install. It does not require any changes to the operating system or to the applications, or any additional hardware.',
            icon: deployment,
            link: "deployment"
        },
    ];

    const theme = useMantineTheme();
    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} padding="xl">
            <feature.icon
                style={{ width: rem(50), height: rem(50) }}
                stroke={2}
                color={theme.colors["deep-blue"][2]}
            />
            <Text c="white" fz="xl" fw={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text fz="md" c="" mt="sm">
                {feature.description}
            </Text>
            <Flex direction="column" justify="end" className="h-full mt-5">
                <Center>
                    <HashLink to={"/howItWorks#" + feature.link}>
                        <Anchor c="deep-blue.2">
                            Read more
                        </Anchor>
                    </HashLink>
                </Center>
            </Flex>
        </Card>
    ));

    return (
        <Container size="xl" py="xl">
            <Container>
                <Title order={1} className={classes.title} ta="center" mt="sm" c="white">
                    Seamlessly boost connectivity, reduce cloud costs and minimize latency with HyperPath
                </Title>
            </Container>
            <Text c="dimmed" className={classes.description} ta="center" mt="md">

            </Text>

            <SimpleGrid cols={{ base: 1, md: 3 }} spacing="xl" mt={50}>
                {features}
            </SimpleGrid>
        </Container>
    );
}