
import ConicalLogo from "./images/Conical logo.svg";
import ConicalVehicle from "./images/conical_vehicle.png";
import ConicalVehicleDiagram from "./images/Conical_vehicle_diagram.png"
export default function ConicalCaseStudy()
{

    const body = [
        {
            "Background" :
                <div>
                    Conical, headquartered in the Netherlands, pioneers in offering shared urban mobility solutions through a fleet of compact electric vehicles. Their unique value proposition lies in the customer's ability to summon a vehicle via a mobile application and have it remotely driven to their location. Following the journey's completion, the vehicle returns to its parking station, guided by a tele-operator, positioning Conical a step ahead in flexibility compared to conventional shared mobility providers.
                </div>
        },
        {
            "Tele-operation with a single cellular link was not enough" :
                <div>
                    To ensure reliable service, Conical's paramount need was the capability to remotely operate their vehicles, irrespective of their location and to do so while the vehicle is moving at high speeds. Such a capability requires an ultra-reliable, low-latency communication channel between the vehicle and the tele-operator, especially during high-speed transits.
                    <br></br>
                    <br></br>
                    However, initial deployment with a singular 5G link presented challenges. The first observation was that certain geographic zones lacked good cellular coverage regardless of which operator was in use. This imposed a restriction on service regions to those with optimal cellular connectivity. Furthermore, the singular link was susceptible to disruptions during high speed mobility due to frequent network handovers and signal fluctuations, compelling Conical to impose speed constraints below what the vehicle was capable of.
                </div>
        },
        {
            "HyperPath seamlessly integrates with Conical's existing setup" :
                <div>
                    Conical deployed the HyperPath software across their vehicles and tele-operation machines. They were able to integrate it without changes to their existing software since HyperPath is installed as a VPN service, which is a feature in all mainstream operating systems support. Since they were using WebRTC for their tele-operation solution, the HyperPath tunnel interface was seen by WebRTC as a local network interface, and thus the WebRTC connection was always established over the HyperPath tunnel.
                </div>
        },
        {
            "Multi-connectivity enables reliable operation in areas with poor coverage" :
                <div>
                    To leverage the multi-connectivity capability, Conical augmented their vehicle setup with an additional cellular modem, sourced from an alternate service provider, so that they always had two unique cellular connections with different operators in each vehicle. HyperPath was then able to combine the two links with its replication scheduler to provide reliable operation in areas that had poor coverage from any single operator. Additionally, the connection became more robust under high speeds since handovers rarely if ever occurred from both operators simultaneously.
                </div>
        },
        {
            "Conical replaces WebRTC with GStreamer and HyperPath" :
                <div>
                    Later on, Conical decided to go a step further and to transition from their WebRTC-based tele-operation solution to a simpler alternative powered by GStreamer. This was made possible because HyperPath provided the P2P connectivity that was previously provided by WebRTC. They were thus able to stream video from GStreamer directly to the HyperPath IP and have it delivered to the tele-operator over a P2P connection. Since GStreamer is significantly more configurable than WebRTC, Conical was able to achieve a higher quality video stream with lower latency, and lower operational complexity.
                </div>
        }

    ];

    const name = "conical";
    const companyName = "Conical";
    const logo = ConicalLogo;
    const location = "Rotterdam, Netherlands";
    const industry = "Shared Urban Mobility";
    const title = "HyperPath helps CONICAL to remotely operate their shared mobility vehicles";
    const website = "https://www.conical.ai/";
    const logoWidth = 200;
    const diagram = ConicalVehicleDiagram;
    const img = ConicalVehicle;
    return {
        body,
        name,
        logo,
        location,
        industry,
        title,
        website,
        logoWidth,
        img,
        companyName,
        diagram

    };
}